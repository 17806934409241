import { createStore, compose } from "redux";
import rootReducers from "./reducers";

const composeEnhancers =
  (process.env.NODE_ENV !== "production" &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const storeConfiguration = () => {
  let store = createStore(rootReducers, {}, composeEnhancers());

  return store;
};
